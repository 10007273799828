@import "../../../assets/scss/variables";

.heroSlider {
    .htSwiperButtonNav {
        font-size: 40px;

        width: 50px;
        height: 50px;
        padding: 0;

        color: #FFF;
        border: none;
        background: none;

        &:after {
            font-size: 0;
        }
    }

    .swiperButtonPrev {
        left: 50px;
    }

    .swiperButtonNext {
        right: 50px;
    }
}

.sliderOne {
    .sliderFixedHeight {
        height: 900px;
    }
    .slide {
        padding-top: 100px;
    }
}

.graBg1 {
    background: linear-gradient(to top, #1344A4, #011054);
}

.slide {
    .content {
        h1 {
            line-height: 65px;

            margin-bottom: 25px;

            animation: Gradient 5s ease infinite;

            color: #FFFFFF;
            background: linear-gradient(110deg, #FFF 33%, rgba(0, 0, 0, 0) 33%),
            linear-gradient(110deg, #F2A192 34%, #F06850 34%);
            -webkit-background-clip: text;
            background-size: 400%;

            -webkit-text-fill-color: transparent;
        }
        p {
            font-size: 16px;
            line-height: 26px;

            margin-bottom: 40px;

            color: #EEF3F6;
        }
    }
    .slideFrontImg {
        text-align: right;
    }
}

// Responsive Css

@media #{$xlg-layout} {
    .sliderOne .sliderFixedHeight {
        height: 700px;
    }
}

@media #{$lg-layout} {
    .slide .slideFrontImg img {
        width: 100%;
    }
    .sliderOne .sliderFixedHeight {
        height: 700px;
    }
}

@media #{$md-layout} {
    .heroSlider {
        .htSwiperButtonNav {
            display: none;
        }
    }
    .slide .content h1 {
        line-height: 49px;
    }
    .slide .slideFrontImg img {
        width: 100%;
    }

    .sliderOne .sliderFixedHeight {
        height: 700px;
    }
}

@media #{$sm-layout} {
    .heroSlider {
        .htSwiperButtonNav {
            display: none;
        }
    }
    .slide .content h1 {
        line-height: 41px;

        margin-bottom: 17px;
    }
    .slide .slideFrontImg img {
        width: 100%;
    }
    .sliderOne .sliderFixedHeight {
        height: auto;
    }
    .sliderOne .sliderFixedHeight {
        padding-top: 167px;
        padding-bottom: 70px;
    }
    .slide .slideFrontImg {
        margin-top: 26px;

        text-align: center;
    }
}

@keyframes Gradient {
    0% {
        background-position: 30% 50%;
    }
    50% {
        background-position: 25% 50%;
    }
    100% {
        background-position: 30% 50%;
    }
}
